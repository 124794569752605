<template>
	<div style="width: 100%;min-height: 486px;" v-loading="pageLoading">
		<el-form ref="form" :rules="rules" :model="formData" label-position="top" v-if="!pageLoading">
			<div class="row form-box">
				<div class="form-view page-left">
					<!-- <el-form-item label="标题" prop="title">
						<el-input v-model="formData.title" placeholder="请输入问题的标题（5～20字），建议根据问题类型、重点进行概括"></el-input>
					</el-form-item> -->
					<el-form-item label="问题描述" prop="content">
						<el-input
							type="textarea"
							:autosize="{ minRows: 10 }"
							placeholder="请简单描述您的问题……"
							v-model="formData.content"
							maxlength="500"
							show-word-limit
						></el-input>
					</el-form-item>
					<el-form-item label="问题图片" prop="image"><upload v-model="formData.image"></upload></el-form-item>
				</div>
				<div class="form-view page-right">
					<el-form-item label="问题分类" prop="major_id">
						<el-cascader v-model="formData.major_id" :options="majorList" :props="{ value: 'id', label: 'name', emitPath: false }"></el-cascader>
					</el-form-item>
					<div class="border"></div>
					<el-form-item label="问题标签" prop="tag_ids">
						<div class="tips">选项最多5个，标签有助于答主给予准确答案</div>
						<el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">{{ tag }}</el-tag>
						<el-input
							placeholder="回车确认添加标签"
							class="input-new-tag"
							v-if="inputVisible"
							v-model="inputValue"
							ref="saveTagInput"
							size="small"
							@keyup.enter.native="handleInputConfirm"
							style="width: 140px;"
						></el-input>
						<el-button v-else icon="el-icon-plus" class="button-new-tag" size="small" @click="showInput">自定义标签</el-button>
					</el-form-item>
					<!-- <div class="border"></div>
					<el-form-item prop="name">
						<div slot="label">
							<el-popover placement="bottom-end" trigger="click">
								<div class="desc-tips">
									<div class="desc-title">金额支付问题，如何结算？</div>
									<div class="desc-content">
										<div>
											1.提问者采纳回答后，80%的提问金额会自动进入被采纳者的账号，20%的提问金额会作为平台的【答谢基金】，后续用于感谢积极帮助他人的用户，用户可以结题。
										</div>
										<div>
											2.问题有回答但是没有满意的答案，可以结题，结题后，90%的提问金额会返回到提问者账号，10%的提问金额会作为平台的【答谢基金】，后续用于感谢积极帮助他人的用户。
										</div>
										<div>
											3.提问7天后，问题有回答，但是没有采纳答案，也没有结题，系统会自动结题，提问金额的50%会返回给提问者账号，另50%会作为平台的【答谢基金】，后续用于感谢积极帮助他人的用户。
										</div>
										<div>
											4.从提问开始，如果没有人回答问题，7天内可以随时自行删除问题，若未自行删除，则系统会在问题发布7天后自动删除。提问金额会全额返回给提问者账号。
										</div>
									</div>
								</div>
								<div slot="reference" class="row cc">
									<span>问题悬赏</span>
									<i style="margin-left: 4px;" class="el-icon-warning-outline"></i>
								</div>
							</el-popover>
						</div>
						<div class="desc">
							<div>在杏田问答，您可以选择您想要了解的板块，提出专业问题，向其他同学或医疗行业从业人士获取答案。</div>
							<div>鼓励大家相互援助，回答问题必有所回报，可以选择以平台内积分、或现金悬赏形式向其他用户求助问题。</div>
							<div>积极回答他人所提出的问题，回答一旦经采纳，也可以获得对应的积分或现金奖励。</div>
						</div>
					</el-form-item>
					<div class="border"></div>
					<el-form-item prop="name">
						<el-radio-group v-model="formData.type">
							<el-radio :label="1">积分悬赏</el-radio>
							<div v-show="formData.type == 1">
								<div class="row coin-view">
									<div class="row cc item a" v-for="(item, index) in integralList" :class="{ active: formData.point == item }" @click="formData.point = item">
										<el-image :src="require('@/assets/coin.png')" class="img"></el-image>
										<span>{{ item }}</span>
									</div>
								</div>
								<el-input v-model="pointInputText" placeholder="自定义悬赏积分100～500" @keyup.enter.native="enterPoint($event)"></el-input>
								<div class="row current-coin">
									<span>我的积分：{{ userInfo.point || 0 }}</span>
									<template v-if="formData.point > userInfo.point || true">
										<span>积分不足</span>
										<el-tooltip class="item" effect="dark" content="回答他人提出的问题，或参与互动获取积分" placement="bottom">
											<span class="green a">去获取</span>
										</el-tooltip>
									</template>
								</div>
							</div>
							<el-radio :label="2">现金悬赏</el-radio>
							<div v-show="formData.type == 2">
								<div class="row coin-view red-packet">
									<div class="row cc item a" v-for="(item, index) in rmbList" :class="{ active: formData.amount == item }" @click="formData.amount = item">
										<el-image :src="require('@/assets/redpacket.png')" class="img"></el-image>
										<span>{{ item }}元</span>
									</div>
								</div>
								<el-input v-model="amountInputText" placeholder="自定义悬赏金额1～10元" @keyup.enter.native="enterAmount($event)"></el-input>
							</div>
						</el-radio-group>
					</el-form-item> -->
					<el-button type="primary" round class="submit-btn" :loading="submitLoading" @click="submitForm()">提交问题</el-button>
					<div class="tips">
						<el-popover placement="bottom-end" trigger="click">
							<div class="desc-tips">
								<div class="desc-title">提问次数说明</div>
								<ol class="desc-content">
									<li>注册成功后可获得{{ userInfo.ask.free_nums }}次免费提问</li>
									<li>回答他人问题后增加{{ userInfo.ask.reply_send_nums }}次提问</li>
									<li>手机端分享朋友、群聊、朋友圈成功后增加{{ userInfo.ask.share_send_nums }}次提问</li>
								</ol>
							</div>
							<div slot="reference" class="row cc" style="justify-content: flex-start;cursor: pointer;">
								<span>{{ askNumsText }}</span>
								<i style="margin-left: 4px;" class="el-icon-warning-outline"></i>
							</div>
						</el-popover>
					</div>
				</div>
			</div>
		</el-form>
		<!-- <div style="height: 30px;"></div> -->
		<el-dialog
			title="微信扫码支付"
			:visible.sync="dialogPayVisible"
			width="380px"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="true"
			:center="false"
			:before-close="beforeCloseDialog"
		>
			<div class="row cc "><el-image style="width: 200px; height: 200px" :src="code_url"></el-image></div>
		</el-dialog>
	</div>
</template>

<script>
let formData = {
	title: '',
	content: '',
	major_id: '',
	tag_ids: [],
	type: '',
	point: '',
	amount: '',
	image: ''
};
import { mapState } from 'vuex';
import hot from '@/pc/components/sidebarRight/hot.vue';
import Editor from '@/pc/components/wangEditor/index.vue';
import Upload from '@/pc/components/upload/index.vue';
export default {
	computed: {
		...mapState(['majorInfo']),
		askNumsText() {
			if (this.userInfo.ask_nums > 0) {
				return `可发布${this.userInfo.ask_nums}次提问`;
			} else {
				return `分享后可获得提问次数`;
			}
		}
	},
	components: { Editor, Upload },
	data() {
		return {
			pageLoading: true,
			formData: JSON.parse(JSON.stringify(formData)),
			dynamicTags: [],
			inputVisible: false,
			inputValue: '',
			integralList: [100, 200, 500, 1000],
			pointInputText: '',
			rmbList: [1, 2, 5, 10],
			amountInputText: '',
			majorList: [],
			userInfo: {},
			rules: {
				// title: [{ required: true, message: '请填写问题标题', trigger: 'blur' }],
				content: [{ required: true, message: '请填写问题内容', trigger: 'blur' }],
				// image: [{ required: true, message: '请上传问题图片', trigger: 'blur' }],
				major_id: [{ required: true, message: '请选择问题分类', trigger: 'change' }]
				// tag_ids: [{ required: true, message: '请添加问题标签', trigger: 'blur' }]
			},
			submitLoading: false,
			code_url: '',
			dialogPayVisible: false,
			payResIntval: ''
		};
	},
	async created() {
		if (this.majorInfo.major_id) this.formData.major_id = this.majorInfo.major_id;
		let res = await this.$service.post('category/majorIndex');
		this.majorList = res.data;
		await this.getUserInfo();
		this.pageLoading = false;
	},
	methods: {
		async getUserInfo() {
			let { data } = await this.$service.post('user_info/detail');
			this.userInfo = data;
		},
		beforeCloseDialog(done) {
			this.$confirm('您确认要取消支付？', '提示', { type: 'warning' })
				.then(() => {
					clearInterval(this.payResIntval);
					done();
					this.$router.replace({
						path: '/user/home'
					});
				})
				.catch(() => {});
		},
		enterPoint() {
			let val = Number(this.pointInputText);
			if (val < 100 || val > 1000) {
				this.$message({
					type: 'error',
					message: '自定义悬赏积分 100-1000'
				});
			} else {
				if (this.integralList.indexOf(val) === -1) this.integralList.push(val);
				this.formData.point = val;
				this.pointInputText = '';
			}
		},
		enterAmount() {
			let val = Number(this.amountInputText);
			if ((val < 1 || val > 10) && val != 0.01) {
				this.$toast('自定义悬赏金额 1-10');
			} else {
				if (this.rmbList.indexOf(val) === -1) this.rmbList.push(val);
				this.formData.amount = val;
				this.amountInputText = '';
			}
		},
		// 表单提交
		submitForm() {
			this.$refs.form.validate(valid => {
				if (valid) {
					let form = JSON.parse(JSON.stringify(this.formData));
					form.tag_ids = form.tag_ids.join(',');
					// if (form.tag_ids == '') {
					// 	this.$message.error('请添加问题标签');
					// } else if (form.type == '') {
					// 	this.$message.error('请选择悬赏类型');
					// } else if (form.type == 1 && form.point == '') {
					// 	this.$message.error('请选择积分悬赏金额');
					// } else if (form.type == 2 && form.amount == '') {
					// 	this.$message.error('请选择现金悬赏金额');
					// } else {
					// }

					if (form.type == 1) {
						delete form.amount;
					} else if (form.type == 2) {
						delete form.point;
					}
					this.submitLoading = true;
					this.$service
						.post('question/add', form)
						.then(askRes => {
							if (askRes.data && askRes.data.orderNo) {
								this.$service
									.post('order/scanPay', { orderNo: askRes.data.orderNo })
									.then(payData => {
										console.log(payData);
										this.code_url = payData.data.payment.code_url;
										this.dialogPayVisible = true;
										this.payResIntval = setInterval(() => {
											this.$service.post('order/orderStatus', { orderNo: askRes.data.orderNo }).then(payRes => {
												if (payRes.data.paystatus == 2) {
													clearInterval(this.payResIntval);
													this.createdAskSuccess(askRes.data.id);
												}
											});
										}, 1000);
									})
									.catch(() => {});
							} else {
								this.createdAskSuccess(askRes.data.id);
							}
						})
						.catch(() => {
							this.submitLoading = false;
						});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		createdAskSuccess(id) {
			this.dialogPayVisible = false;
			this.submitLoading = false;
			this.formData = JSON.parse(JSON.stringify(formData));
			this.$alert('问题创建成功', '提示', {
				type: 'success'
			}).then(() => {
				this.$router.replace({
					path: '/ask/success',
					query: { id }
				});
			});
		},
		// 自定义标签
		handleClose(tag) {
			let index = this.dynamicTags.indexOf(tag);
			this.dynamicTags.splice(index, 1);
			this.formData.tag_ids.splice(index, 1);
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		async handleInputConfirm() {
			let inputValue = this.inputValue.trim();
			if (this.dynamicTags.length >= 5) {
				this.$message({
					type: 'error',
					message: '最多添加5个标签'
				});
			} else if (this.formData.major_id === '') {
				this.$message({
					type: 'error',
					message: '请先选择问题分类'
				});
			} else if (inputValue === '') {
				this.$message({
					type: 'error',
					message: '请输入标签名称'
				});
			} else {
				let res = await this.$service.post('category/tagAdd', { tag_names: inputValue, major_id: this.formData.major_id });
				this.dynamicTags.push(inputValue);
				this.formData.tag_ids.push(res.data);
				this.inputVisible = false;
				this.inputValue = '';
			}
		}
	}
};
</script>

<style scoped lang="less">
@import './add.less';
</style>
