<template>
	<div class="part-box">
		<div class="part-title">
			<div class="row title"><span class="row title-text">热门问题</span></div>
		</div>
		<div class="col list" v-loading="loading">
			<template v-for="(item, index) in dataList">
				<div class="row cb item a" @click="navAskDetail(item)">
					<div class="row left">
						<div class="row cc rank">{{ index + 1 }}</div>
						<div class="name text-ellipsis">{{ item.title }}</div>
					</div>
					<div class="count">
						<i class="el-icon-view"></i>
						{{ item.view_num }}
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['initSuccess'],
	data() {
		return {
			loading: true,
			dataList: []
		};
	},
	created() {
		this.$service
			.post('question/hotQuestion', { pageSize: 5 })
			.then(res => {
				this.dataList = res.data.data;
			})
			.finally(() => {
				this.loading = false;
				this.initSuccess();
			});
	},
	methods: {
		navAskDetail(item) {
			this.$router.push({
				path: '/ask/detail',
				query: {
					id: item.id
				}
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './common.less';
@import './hot.less';
</style>
