<template>
	<div>
		<el-upload
			class="uploader"
			:class="{ hidden: valueArr.length >= 9 }"
			:action="action"
			:on-success="handleSuccess"
			:on-remove="handleRemove"
			multiple
			:limit="9"
			:file-list="fileList"
			list-type="picture-card"
		>
			<i class="el-icon-plus"></i>
		</el-upload>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	props: {
		value: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			fileList: []
		};
	},
	computed: {
		...mapState(['apiUrl']),
		action() {
			return this.apiUrl + 'ajax/upload';
		},
		valueArr() {
			if (this.value) {
				return this.value.split(',');
			} else {
				return [];
			}
		}
	},
	created() {
		this.fileList = this.valueArr.map(e => {
			return {
				url: this.$store.getters.oss(e)
			};
		});
	},
	methods: {
		handleRemove(file, fileList) {
			let index = this.fileList.findIndex(e => e.url == file.url);
			this.valueArr.splice(index, 1);
			this.$emit('input', this.valueArr.join(','));
		},
		handleSuccess(e) {
			this.valueArr.push(e.data.url);
			this.$emit('input', this.valueArr.join(','));
		}
	}
};
</script>

<style scoped lang="less">
/deep/ .uploader {
	width: 384px;
	.el-upload,
	.el-upload-list__item {
		width: 120px;
		height: 120px;
		box-sizing: border-box;
		transition: none;
	}
	.el-upload--picture-card {
		line-height: 120px;
	}
}
/deep/ .hidden {
	.el-upload--picture-card {
		display: none;
	}
}
</style>
