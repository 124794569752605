<template>
	<div style="font-size: 16px;"><div ref="editor" style="width: 100%;"></div></div>
</template>

<script>
import E from 'wangeditor';
export default {
	props: {
		value: {
			type: String,
			default: ''
		},
		meanArray: {
			// 自定义菜单
			type: Array,
			default: null
		},
		height: {
			type: Number,
			default: 580
		},
		placeholder: {
			type: String,
			default: '请输入问题的详情，可附图或其他资料补充说明'
		}
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	watch: {
		value: function(value) {
			if (value !== this.editor.txt.html()) {
				this.editor.txt.html(this.value);
			}
		}
	},
	data() {
		return {
			// 默认有这么多菜单，meanArray有值以meanArray为准
			defaultMeanus: [
				'head',
				'bold',
				'fontSize',
				'fontName',
				'italic',
				'underline',
				'strikeThrough',
				'indent',
				'lineHeight',
				'foreColor',
				'backColor',
				'link',
				'list',
				'justify',
				'quote',
				'emoticon',
				'image',
				// 'video',
				'table',
				'code',
				'splitLine',
				'undo',
				'redo'
			],
			editor: ''
		};
	},
	methods: {
		init() {
			const _this = this;
			this.editor = new E(this.$refs.editor);
			// 不限制上传图片后缀
			this.editor.config.uploadImgAccept = [];
			// 配置服务端上传接口
			this.editor.config.uploadImgServer = `${this.$store.getters.apiUrl}ajax/upload`;
			this.editor.config.uploadFileName = 'file';
			this.editor.config.uploadImgHooks = {
				customInsert: (insertImgFn, result) => {
					// insertImgFn 把图片插入到编辑器
					insertImgFn(this.$store.getters.oss(result.data.url));
				}
			};

			this.setMenus(); // 设置菜单
			this.editor.config.onchange = html => {
				_this.$emit('change', html); // 将内容同步到父组件中
			};
			this.editor.config.height = this.height;
			this.editor.config.zIndex = 1;
			this.editor.config.placeholder = this.placeholder;
			this.editor.create(); // 创建编辑器
			this.setHtml(this.value);
		},
		setMenus() {
			// 设置菜单
			if (this.meanArray) {
				this.editor.config.menus = this.meanArray;
			} else {
				this.editor.config.menus = this.defaultMeanus;
			}
		},
		getHtml() {
			// 得到文本内容
			return this.editor.txt.html();
		},
		setHtml(txt) {
			// 设置富文本里面的值
			this.editor.txt.html(txt);
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	}
};
</script>
